/**
 * Enables zero width space, zero width non-joiner, and non-breaking space in a text by replacing
 * all occurrences of &#8203;, &zwnj;, and &nbsp;
 * in the text with the actual encoded characters, thus letting the browser
 * add these characters when appropriate while rendering the text.
 *
 * @param text Unencoded text containing the &#8203;, &zwnj;, and &nbsp; HTML entities
 * @returns zero width space, zero width non-joiner, and non-breaking space encoded text
 */
export const sanitizeText = (text: string) => {
  return text
    .replace(/&#8203;/gi, String.fromCharCode(8203))
    .replace(/&zwnj;/gi, String.fromCharCode(8204))
    .replace(/&nbsp;/gi, String.fromCharCode(160));
};
