import React, { useContext } from 'react';
import { Theme } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { Context } from '../../Context';
import { ConsumptionAndEmissions } from './ConsumptionAndEmissions';
import { Disclaimers } from './Disclaimers';

export const StageLegalWrapper = styled.div`
  width: auto;
  overflow: hidden;
  max-width: 1920px;
  padding: ${({ theme }: { theme: Theme }) =>
    `var(${theme.responsive?.spacing.m}) var(${theme.responsive?.spacing.pageMargin}) var(${theme.responsive?.spacing.m}) var(${theme.responsive?.spacing.pageMargin});`};
`;

interface StageLegalProps {
  consumptionAndEmissions?: string[];
  disclaimers?: string[];
}

const StageLegal: React.FC<StageLegalProps> = ({ consumptionAndEmissions, disclaimers }) => {
  const { localeService, vueFormatterService } = useContext(Context);
  // Checks if consumptionAndEmissions and disclaimers are not empty string arrays
  const hasContent =
    (consumptionAndEmissions && consumptionAndEmissions.length > 0) ||
    (disclaimers && disclaimers.length > 0);

  if (!hasContent) return null;

  return (
    <StageLegalWrapper data-testid="consumption-emissions-data">
      {vueFormatterService && localeService && (
        <ConsumptionAndEmissions
          caeIds={consumptionAndEmissions}
          vueFormatterService={vueFormatterService}
          localeService={localeService}
        />
      )}
      <Disclaimers disclaimers={disclaimers} />
    </StageLegalWrapper>
  );
};

export default StageLegal;
