import React, { createContext, PropsWithChildren, useMemo } from 'react';

import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { Logger } from '@feature-hub/logger';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { AudiVideoServiceInterfaceV1 } from '@oneaudi/video-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import type { LayerManagerV27 } from '@volkswagen-onehub/layer-manager';

interface FeatureAppEnv {
  featureAppId: string;
  featureAppName?: string;
}

export interface ContextState {
  readonly featureAppEnv: FeatureAppEnv;
  readonly localeService?: LocaleServiceV1;
  readonly loggerService?: Logger;
  readonly trackingService?: TrackingServiceV2;
  readonly videoService?: AudiVideoServiceInterfaceV1;
  readonly vueFormatterService?: VueFormatterServiceInterfaceV1;
  readonly layerManager?: LayerManagerV27;
}

export const Context = createContext<ContextState>({} as ContextState);

export interface ContextProviderProps {
  readonly featureAppEnv: FeatureAppEnv;
  readonly localeService?: LocaleServiceV1;
  readonly loggerService?: Logger;
  readonly trackingService?: TrackingServiceV2;
  readonly videoService?: AudiVideoServiceInterfaceV1;
  readonly vueFormatterService?: VueFormatterServiceInterfaceV1;
  readonly layerManager?: LayerManagerV27;
}

const ContextProvider = ({
  children,
  featureAppEnv,
  localeService,
  loggerService: logger,
  trackingService,
  videoService,
  vueFormatterService,
  layerManager,
}: PropsWithChildren<ContextProviderProps>) => {
  const value = useMemo(
    () => ({
      featureAppEnv,
      localeService,
      loggerService: logger,
      trackingService,
      videoService,
      vueFormatterService,
      layerManager,
    }),
    [],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
export default ContextProvider;
