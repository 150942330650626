/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React, { useEffect } from 'react';
import { ContentServiceV1 } from '@oneaudi/content-service';
import StageContainer from './components/stage-container/StageContainer';
import { FalconContent, StageProps, StagePropsFACE } from '../types';

import { transformCmsDataToProps } from './utils/transformCmsDataToProps';

interface FeatureAppProps {
  contentService: ContentServiceV1;
}

const FeatureApp: React.FC<FeatureAppProps> = (props: FeatureAppProps) => {
  const { contentService } = props;

  const [content, setContent] = React.useState<StageProps>(
    transformCmsDataToProps(contentService.getContent() as StagePropsFACE | FalconContent),
  );

  useEffect(() => {
    const listener = () => {
      setContent(
        transformCmsDataToProps(contentService.getContent() as StagePropsFACE | FalconContent),
      );
    };
    contentService.onContentChange(listener);

    return () => {
      contentService.removeOnContentChange(listener);
    };
  }, [contentService]);

  if (typeof content === 'undefined') {
    return <p>No content found for Stage Component</p>;
  }

  return <StageContainer content={content} />;
};

export default FeatureApp;
