import React from 'react';
import { Theme } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { UeElement } from '@oneaudi/falcon-tools';
import { AppearanceProps, CtaButton, TexContentProps } from '../../../types';
import StageButtons from './StageButtons';
import RichText from './RichText';
import { Tracking } from '../../hooks/useTracking';

interface StageContentWrapProps {}

const StageContentWrap = styled.div<StageContentWrapProps>`
  width: auto;
  overflow: hidden;
  max-width: 1920px;
  padding: 0 4%;
  padding: ${({ theme }: { theme: Theme }) => {
    return `var(${theme.responsive?.spacing.l}) var(${theme.responsive?.spacing.pageMargin}) var(${theme.responsive?.spacing.xxl}) var(${theme.responsive?.spacing.pageMargin});`;
  }};
  position: relative;
  z-index: 4 !important;
  white-space: pre-wrap;
`;

interface StageContentProps {
  text?: TexContentProps;
  buttons?: CtaButton[];
  appearance: AppearanceProps;
  tracking: Tracking;
  isMobile?: boolean;
  hasConsumptionAndEmission?: boolean;
}

const StageContent: React.FC<StageContentProps> = ({
  text,
  buttons,
  appearance,
  tracking,
  isMobile,
}) => {
  const { headline, headingTag, subline } = text || {};

  return (
    <div>
      {/* delete padding bottom if mode large and bottom left */}
      <StageContentWrap data-testid="headline-subline-buttons-data">
        {headline && (
          <UeElement type="text" property="textHeadline" label="Headline">
            {(ueProps) => (
              <RichText
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                as={headingTag || 'h1'}
                spaceStackEnd="xs"
                spaceStackStart="xl"
                variant="order1"
                text={headline}
                {...ueProps}
              />
            )}
          </UeElement>
        )}
        {subline && (
          <UeElement type="text" property="textSubline" label="Subline">
            {(ueProps) => (
              <RichText
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                as="span"
                spaceStackEnd="l"
                variant="order4"
                text={subline}
                {...ueProps}
              />
            )}
          </UeElement>
        )}
        {!isMobile && (
          <StageButtons appearance={appearance} buttons={buttons} tracking={tracking} />
        )}
      </StageContentWrap>
      {isMobile && (
        <StageButtons
          appearance={appearance}
          buttons={buttons}
          tracking={tracking}
          isMobile={isMobile}
        />
      )}
    </div>
  );
};

export default StageContent;
